import showToast from './showToast.js'
export default {
    /**
     * @param opts IToastParams|string
     *
     */
    success: (opts) => {
        showToast(opts, {
            title: 'Sucesso',
            text: 'Operação realizada com sucesso!',
            toastClass: 'toast-success',
            toastBackgroundClass: 'alert-success',
            icon: 'check_circle_outline',
        })
    },
    /**
     * @param opts IToastParams|string
     */
    error: (opts) => {
        showToast(opts, {
            title: 'Erro',
            text: 'Um erro ocorreu.',
            toastClass: 'toast-fail',
            toastBackgroundClass: 'alert-danger',
            icon: 'error_outline',
        })
    },
    /**
     * @param opts IToastParams|string
     */
    warning: (opts) => {
        showToast(opts, {
            title: 'Aviso',
            text: 'Este é um aviso.',
            toastClass: 'toast-warning',
            toastBackgroundClass: 'alert-warning',
            icon: 'warning_amber',
        })
    },
    /**
     * @param opts IQuestionToastParams
     */
    question: (opts) => {
        if (!opts.primaryActionCallback) {
            throw new Error('primaryActionCallback não especificado')
        }

        showToast(opts, {
            text: '',
            primaryActionText: 'Sim',
            primaryActionCallback: () => {},
            secondaryActionText: 'Dispensar',
            toastClass: 'toast-primary',
            toastBackgroundClass: 'bg-light',
            titleClass: 'text-dark',
            icon: 'help_outline',
            title: '',
        })
    },
}